import React from "react"
import Layout from "../layouts"
import Seo from "../components/seo"
import { Link } from "gatsby"
import {
  Section,
  SectionTitle,
  SectionPageTitle,
  SectionDescription,
  Container,
  BreadCrumb,
  MarkdownContent,
  MarkdownBody,
  MarkDownRow,
  MarkDownTwocol,
  MarkDownLeft,
  MarkDownRight,
} from "../components/Section"
import { StaticImage } from "gatsby-plugin-image"

const ContentPage = ({ location }) => (
  <Layout isHeader={true} location={location}>
    <Seo title="ContentPage" />
    <BreadCrumb>
      <Container maxWidth="100%" pl="3%" pr="3%">
        <Link to="/">Home </Link>
        <span>Content</span>
      </Container>
    </BreadCrumb>
    <Section
      xpt="170px"
      mpt="170px"
      pt="120px"
      pb="30px"
      bdrColor="#CCD6E0"
      bdrWidth="1px"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionPageTitle mb="10px">
          Lorem Ipsum is simply dummy text of the printing industry
        </SectionPageTitle>
        <SectionDescription maxWidth="1496px" mb="40px">
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry lorem Ipsum has been the industry’s standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic. Lorem Ipsum
            is simply dummy text of the printing and typesetting industry lorem
            Ipsum has been the industry’s standard dummy text ever since the
            1500s, when an unknown printer took a galley of type and scrambled
            it to make a type specimen book. It has survived not only five
            centuries, but also the leap into electronic. Lorem Ipsum is simply
            dummy text of the printing and typesetting industry lorem Ipsum has
            been the industry’s standard dummy text ever since the 1500s, when
            an unknown printer
          </p>
        </SectionDescription>
      </Container>
    </Section>
    <Section xpt="60px" mpt="60px" pt="60px" pb="0" bgColor="#F0F8FF">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionTitle mb="10px">Providing 3 Roof Style</SectionTitle>
        <SectionDescription maxWidth="1496px" mb="40px">
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry lorem Ipsum has been the industry’s standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic. Lorem Ipsum
            is simply dummy text of the printing and typesetting industry lorem
            Ipsum has been the industry’s standard dummy text ever since the
            1500s, when an unknown printer took a galley of type and scrambled
            it to make a type specimen book. It has survived not only five
            centuries, but also the leap into electronic.
          </p>
        </SectionDescription>
      </Container>
    </Section>

    <Section
      xpt="30px"
      mpt="20px"
      pt="15px"
      xpb="30px"
      mpb="20px"
      pb="15px"
      bgColor="transparent"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <MarkdownBody>
          <MarkdownContent maxWidth="1496px">
            <h2>Lorem Ipsum is simply dummy text of the printing industry</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old. Richard McClintock, a Latin
              professor at Hampden-Sydney College in Virginia, looked up one of
              the more obscure Latin words, consectetur, from a Lorem Ipsum
              passage, and going through the cites of the word in classical
              literature, discovered the undoubtable source. Lorem Ipsum comes
              from sections 1.10.32 and 1.10.33 of “de Finibus Bonorum et
              Malorum” (The Extremes of Good and Evil) by Cicero, written in 45
              BC. This book is a treatise on the theory of ethics, very popular
              during the Renaissance. The first line of Lorem Ipsum, “Lorem
              ipsum dolor sit amet..”, comes from a line in section 1.10.32.
            </p>
          </MarkdownContent>
        </MarkdownBody>
      </Container>
    </Section>
    <Section
      xpt="30px"
      mpt="20px"
      pt="15px"
      xpb="30px"
      mpb="20px"
      pb="15px"
      bgColor="transparent"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <MarkdownBody>
          <MarkdownContent maxWidth="1496px">
            <MarkDownRow>
              <MarkDownTwocol>
                <h2>
                  Lorem Ipsum is simply dummy text of the printing industry
                </h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <p>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Richard McClintock,
                  a Latin professor at Hampden-Sydney College in Virginia,
                  looked up one of the more obscure Latin words, consectetur,
                  from a Lorem Ipsum passage, and going through the cites of the
                  word in classical literature, discovered the undoubtable
                  source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of
                  “de Finibus Bonorum et Malorum” (The Extremes of Good and
                  Evil) by Cicero, written in 45 BC. This book is a treatise on
                  the theory of ethics, very popular during the Renaissance. The
                  first line of Lorem Ipsum, “Lorem ipsum dolor sit amet..”,
                  comes from a line in section 1.10.32.
                </p>
              </MarkDownTwocol>
              <MarkDownTwocol>
                <StaticImage
                  src="../images/aframe-roof-style-big.jpg"
                  placeholder="blurred"
                  alt="aframe-roof-style"
                />
              </MarkDownTwocol>
            </MarkDownRow>
          </MarkdownContent>
        </MarkdownBody>
      </Container>
    </Section>
    <Section
      xpt="30px"
      mpt="20px"
      pt="15px"
      xpb="30px"
      mpb="20px"
      pb="15px"
      bgColor="transparent"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <MarkdownBody>
          <MarkdownContent maxWidth="1496px">
            <h2>Lorem Ipsum is simply dummy text of the printing industry</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old. Richard McClintock, a Latin
              professor at Hampden-Sydney College in Virginia, looked up one of
              the more obscure Latin words, consectetur, from a Lorem Ipsum
              passage, and going through the cites of the word in classical
              literature, discovered the undoubtable source. Lorem Ipsum comes
              from sections 1.10.32 and 1.10.33 of “de Finibus Bonorum et
              Malorum” (The Extremes of Good and Evil) by Cicero, written in 45
              BC. This book is a treatise on the theory of ethics, very popular
              during the Renaissance. The first line of Lorem Ipsum, “Lorem
              ipsum dolor sit amet..”, comes from a line in section 1.10.32.
            </p>

            <h2>Lorem Ipsum is simply dummy text of the printing industry</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old. Richard McClintock, a Latin
              professor at Hampden-Sydney College in Virginia, looked up one of
              the more obscure Latin words, consectetur, from a Lorem Ipsum
              passage, and going through the cites of the word in classical
              literature, discovered the undoubtable source. Lorem Ipsum comes
              from sections 1.10.32 and 1.10.33 of “de Finibus Bonorum et
              Malorum” (The Extremes of Good and Evil) by Cicero, written in 45
              BC. This book is a treatise on the theory of ethics, very popular
              during the Renaissance. The first line of Lorem Ipsum, “Lorem
              ipsum dolor sit amet..”, comes from a line in section 1.10.32.
            </p>

            <ul>
              <li>Lorem Ipsum is simply dummy text of the printing industry</li>
              <li>Lorem Ipsum is simply dummy text of the printing industry</li>
              <li>Lorem Ipsum is simply dummy text of the printing industry</li>
              <li>Lorem Ipsum is simply dummy text of the printing industry</li>
              <li>Lorem Ipsum is simply dummy text of the printing industry</li>
            </ul>

            <h2>Lorem Ipsum is simply dummy text of the printing industry</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old. Richard McClintock, a Latin
              professor at Hampden-Sydney College in Virginia, looked up one of
              the more obscure Latin words, consectetur, from a Lorem Ipsum
              passage, and going through the cites of the word in classical
              literature, discovered the undoubtable source. Lorem Ipsum comes
              from sections 1.10.32 and 1.10.33 of “de Finibus Bonorum et
              Malorum” (The Extremes of Good and Evil) by Cicero, written in 45
              BC. This book is a treatise on the theory of ethics, very popular
              during the Renaissance. The first line of Lorem Ipsum, “Lorem
              ipsum dolor sit amet..”, comes from a line in section 1.10.32.
            </p>

            <h3>Lorem Ipsum is simply dummy</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>

            <h3>Lorem Ipsum is simply dummy</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>

            <h3>Lorem Ipsum is simply dummy</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
          </MarkdownContent>
        </MarkdownBody>
      </Container>
    </Section>
    <Section
      xpt="30px"
      mpt="20px"
      pt="15px"
      xpb="60px"
      mpb="60px"
      pb="30px"
      bgColor="transparent"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <MarkdownBody>
          <MarkdownContent maxWidth="1496px">
            <h2>Lorem Ipsum is simply dummy text of the printing industry</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old. Richard McClintock, a Latin
              professor at Hampden-Sydney College in Virginia, looked up one of
              the more obscure Latin words, consectetur, from a Lorem Ipsum
              passage, and going through the cites of the word in classical
              literature, discovered the undoubtable source. Lorem Ipsum comes
              from sections 1.10.32 and 1.10.33 of “de Finibus Bonorum et
              Malorum” (The Extremes of Good and Evil) by Cicero, written in 45
              BC. This book is a treatise on the theory of ethics, very popular
              during the Renaissance. The first line of Lorem Ipsum, “Lorem
              ipsum dolor sit amet..”, comes from a line in section 1.10.32.
            </p>
            <MarkDownRow>
              <MarkDownLeft>
                <h3>
                  Lorem Ipsum is simply dummy text of the printing industry
                </h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              </MarkDownLeft>
              <MarkDownRight>
                <StaticImage
                  src="../images/aframe-roof-style-big.jpg"
                  placeholder="blurred"
                  alt="aframe-roof-style"
                />
              </MarkDownRight>
            </MarkDownRow>
            <hr />
            <MarkDownRow>
              <MarkDownLeft>
                <h3>
                  Lorem Ipsum is simply dummy text of the printing industry
                </h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              </MarkDownLeft>
              <MarkDownRight>
                <StaticImage
                  src="../images/aframe-roof-style-big.jpg"
                  placeholder="blurred"
                  alt="aframe-roof-style"
                />
              </MarkDownRight>
            </MarkDownRow>
            <hr />
            <MarkDownRow>
              <MarkDownLeft>
                <h3>
                  Lorem Ipsum is simply dummy text of the printing industry
                </h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              </MarkDownLeft>
              <MarkDownRight>
                <StaticImage
                  src="../images/aframe-roof-style-big.jpg"
                  placeholder="blurred"
                  alt="aframe-roof-style"
                />
              </MarkDownRight>
            </MarkDownRow>
          </MarkdownContent>
        </MarkdownBody>
      </Container>
    </Section>
  </Layout>
)

export default ContentPage
